/* @import url(http://fonts.googleapis.com/css?family=Roboto:400,300,100,700,100italic,300italic,400italic,700italic); */

@import url(https://fonts.googleapis.com/css?family=Prompt);
@media (min-width: 1200px) {
    .container {
        width: 1170px !important;
    }
}

* {
    font-family: 'Prompt', sans-serif;
}

p,
span,
label {
    font-family: 'Prompt', sans-serif;
}

body {
    /* padding-top: 100px; */
    font-family: 'Prompt', sans-serif;
    background: #fff;
    font-weight: 300;
    color: #454545;
    font-size: 15px;
    line-height: 26px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    color: #272727;
}

footer {
    position: fixed;
    height: 30px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    z-index: 10;
}

/* @font-face {
  font-family: 'Roboto-regular';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Regular.woff') format('woff'), url('../fonts/Roboto-Regular.ttf') format('truetype'), url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-light';
  src: url('../fonts/Roboto-Light.eot');
  src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Light.woff') format('woff'), url('../fonts/Roboto-Light.ttf') format('truetype'), url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-thin';
  src: url('../fonts/Roboto-Thin.eot');
  src: url('../fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Thin.woff') format('woff'), url('../fonts/Roboto-Thin.ttf') format('truetype'), url('../fonts/Roboto-Thin.svg#Roboto-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-medium';
  src: url('../fonts/Roboto-Medium.eot');
  src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Medium.woff') format('woff'), url('../fonts/Roboto-Medium.ttf') format('truetype'), url('../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
} */

.Light49 {
    /* font-family: 'Roboto-thin'; */
    font-size: 49px;
}

a {
    color: #45aed6;
    -webkit-transition: color 400ms, background-color 400ms;
    -moz-transition: color 400ms, background-color 400ms;
    -o-transition: color 400ms, background-color 400ms;
    transition: color 400ms, background-color 400ms;
}

a:hover,
a:focus {
    text-decoration: none;
    color: #2a95be;
}

hr {
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #fff;
}

.gradiant {
    background-image: -moz-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
    background-image: -webkit-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
    background-image: -ms-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
}

.gradiant-horizontal {
    background-image: -moz-linear-gradient(4deg, #2caab3 0%, #2c8cb3 100%);
    background-image: -webkit-linear-gradient(4deg, #2caab3 0%, #2c8cb3 100%);
    background-image: -ms-linear-gradient(4deg, #2caab3 0%, #2c8cb3 100%);
}

.section-header {
    margin-bottom: 50px;
    margin-top: 50px;
}

.section-header .section-title {
    font-size: 36px;
    color: #454545;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 20px;
    margin: 0 0 20px;
    /* font-family: 'Roboto-medium'; */
    font-weight: 500;
}

.section-header .section-title:before {
    content: "";
    position: absolute;
    width: 140px;
    bottom: 0;
    left: 50%;
    margin-left: -70px;
    height: 2px;
    background: #00BFFF;
}

.section-header .section-title:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: -11px;
    left: 50%;
    margin-left: -12px;
    border: 5px solid #fff;
    border-radius: 20px;
    background: #00BFFF;
}

.btn {
    border-width: 0;
    border-bottom-width: 3px;
    border-radius: 3px;
}

.btn.btn-primary {
    background: #00BFFF;
    border-color: #00BFFF;
    border-radius: 25px;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus {
    background: #00BFFF;
}

.column-title {
    margin-top: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
    position: relative;
}

.column-title:after {
    content: " ";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 40%;
    border-bottom: 1px solid #45aed6;
}

ul.nostyle {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

ul.nostyle i {
    color: #45aed6;
}

.scaleIn {
    -webkit-animation-name: scaleIn;
    animation-name: scaleIn;
}

@media(min-width:767px) {
    .navbar {
        padding: 20px 0;
        -webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
        -moz-transition: background .5s ease-in-out, padding .5s ease-in-out;
        transition: background .5s ease-in-out, padding .5s ease-in-out;
    }
    .top-nav-collapse {
        padding: 0;
    }
}

@-webkit-keyframes scaleIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scaleIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

/*************************
*******Header******
**************************/

.navbar.navbar-default {
    padding-top: 15px;
    border: 0;
    border-radius: 0;
    background: #fff;
}

.navbar.navbar-default .navbar-toggle {
    margin-top: 32px;
}

.navbar.navbar-default .navbar-brand {
    height: auto;
    padding: 22px 15px 15px;
}

@media only screen and (min-width: 768px) {
    #main-menu.navbar-default {
        background: rgba(255, 255, 255, 1);
        -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
    }
    .menuLanguage {
        padding-top: 10px;
        /* padding-bottom: 20px;  */
        font-size: 16px;
        /* font-family: 'Roboto-regular'; */
        font-weight: normal;
        color: #404455 !important;
    }
    #main-menu.navbar-default .navbar-nav>li>a {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 16px;
        /* font-family: 'Roboto-regular'; */
        font-weight: normal;
        /* color: #ffffff; */
    }
    #main-menu.navbar-default .navbar-nav>li.active>a,
    #main-menu.navbar-default .navbar-nav>li.open>a,
    #main-menu.navbar-default .navbar-nav>li:hover>a {
        background: transparent;
        border-top: 0px solid #00BFFF;
        color: #00BFFF;
    }
    #main-menu.navbar-default .dropdown-menu {
        padding: 0 20px;
        min-width: 220px;
        background-color: rgba(26, 28, 40, 0.9);
        border: 0;
        border-radius: 0;
        box-shadow: none;
        -webkit-box-shadow: none;
        background-clip: inherit;
    }
    #main-menu.navbar-default .dropdown-menu>li {
        border-left: 3px solid transparent;
        margin-left: -20px;
        padding-left: 17px;
        -webit-transition: border-color 400ms;
        transition: border-color 400ms;
    }
    #main-menu.navbar-default .dropdown-menu>li>a {
        border-top: 1px solid #404455;
        padding: 15px 0;
        color: #eeeeee;
    }
    #main-menu.navbar-default .dropdown-menu>li:first-child>a {
        border-top: 0;
    }
    #main-menu.navbar-default .dropdown-menu>li.active,
    #main-menu.navbar-default .dropdown-menu>li.open,
    #main-menu.navbar-default .dropdown-menu>li:hover {
        border-left-color: #45aed6;
    }
    #main-menu.navbar-default .dropdown-menu>li.active>a,
    #main-menu.navbar-default .dropdown-menu>li.open>a,
    #main-menu.navbar-default .dropdown-menu>li:hover>a {
        color: #45aed6;
        background-color: transparent;
    }
}

#main {
    overflow: hidden;
}

#main .item {
    height: 600px;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}

#main .slider-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
}

#main .slider-inner h2 {
    margin-top: 200px;
    font-size: 40px;
    line-height: 1;
    text-transform: capitalize;
    /* font-family: 'Roboto-thin'; */
    color: #fff;
    text-align: center;
}

#main .slider-inner p {
    margin-top: 20px;
    font-size: 16px;
    line-height: 25px;
    text-transform: capitalize;
    /* font-family: 'Roboto-regular'; */
    color: #fff;
    text-align: center;
}

#main .slider-inner h2>span {
    color: #45aed6;
}

#main .slider-inner .btn {
    margin-top: 20px;
    background: transparent;
    border: 3px solid #00BFFF;
    border-radius: 30px;
}

#main .slider-inner .btn.btn-primary {
    padding: 10px 46px;
    font-size: 22px;
}

#main .slider-inner .btn.btn-primary:hover {
    background: #00BFFF;
}

#main .owl-prev,
#main .owl-next {
    position: absolute;
    top: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 30px;
    display: inline-block;
    margin-top: -35px;
    height: 70px;
    width: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 100px;
    z-index: 5;
    -webkit-transition: background-color 400ms;
    transition: background-color 400ms;
}

#main .owl-prev:hover,
#main .owl-next:hover {
    background-color: #45aed6;
}

#main .owl-prev {
    left: -35px;
    text-indent: 14px;
}

#main .owl-next {
    right: -35px;
    text-indent: -14px;
}

#bar {
    width: 0%;
    max-width: 100%;
    height: 4px;
    background: #45aed6;
}

#progressBar {
    margin-top: -4px;
    position: relative;
    z-index: 999;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
}

#features {
    padding: 100px 0;
}

#features .media.service-box:first-child {
    margin-top: 80px;
}

#service .text-block p {
    color: #0f467a;
    font-size: 3rem;
    font-weight: 600;
    padding-top: 100px;
}

.media.service-box {
    margin: 25px 0;
    text-align: center;
}

.media.service-box .pull-left {
    width: 100%;
    padding-bottom: 25px;
}

.media.service-box .pull-left>i {
    font-size: 42px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    width: 64px;
    border-radius: 100%;
    color: #4c4c4c;
    -webkit-box-shadow: inset 0 0 0 1px #d7d7d7;
    transition: background-color 400ms, background-color 400ms;
    position: relative;
}

.media.service-box .pull-left>i:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    margin-top: -10px;
    right: -10px;
}

.media-body h4 {
    margin-bottom: 15px;
    /* font-family: 'Roboto-light'; */
    font-size: 22px;
    color: #454545;
}

.media-body p {
    line-height: 20px;
}

.media.service-box:hover .pull-left>i {
    /*  background-image: -moz-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
  background-image: -webkit-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
  background-image: -ms-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
*/
    color: #000;
    box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.8);
    -webkit-box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.8);
}

#portfolio {
    padding: 87px 0;
    background: #f5f5f5;
}

#portfolio .portfolio-filter {
    list-style: none;
    padding: 0;
    margin: 0 0 50px;
    display: inline-block;
}

#portfolio .portfolio-filter>li {
    float: left;
    display: block;
}

#portfolio .portfolio-filter>li a {
    display: block;
    padding: 7px 15px;
    /* font-family: 'Roboto-regular'; */
    color: #64686d;
    position: relative;
}

#portfolio .portfolio-filter>li a:hover,
#portfolio .portfolio-filter>li a.active {
    background: #00BFFF;
    color: #fff;
    /* box-shadow: 0 -3px 0 0 #318daf inset;
  -webkit-box-shadow: 0 -3px 0 0 #318daf inset;*/
}

/*#portfolio .portfolio-filter > li a.active:after {
  content: " ";
  position: absolute;
  bottom: -17px;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #318daf transparent transparent transparent;
}*/

#portfolio .portfolio-filter>li:first-child a {
    border-radius: 0px 0 0 0px;
}

#portfolio .portfolio-filter>li:last-child a {
    /* border-radius: 0 4px 4px 0;*/
}

#portfolio .portfolio-items {
    margin: -15px;
}

#portfolio .portfolio-item {
    width: 24.9%;
    float: left;
    padding: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

#portfolio .portfolio-item .portfolio-item-inner {
    position: relative;
}

#portfolio .portfolio-item .portfolio-item-inner .portfolio-info {
    opacity: 0;
    transition: opacity 400ms;
    -webkit-transition: opacity 400ms;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
}

#portfolio .portfolio-item .portfolio-item-inner .portfolio-info h3 {
    font-size: 16px;
    line-height: 1;
    margin: 0;
    color: #fff;
}

#portfolio .portfolio-item .portfolio-item-inner .portfolio-info .preview {
    position: absolute;
    top: -18px;
    right: 12px;
    border-radius: 50%;
    background-image: -moz-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
    background-image: -webkit-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
    background-image: -ms-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    box-shadow: 0 0 0 2px #fff;
    -webkit-box-shadow: 0 0 0 2px #fff;
}

#portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
}

/* Start: Recommended Isotope styles */

/**** Isotope Filtering ****/

.isotope-item {
    z-index: 2;
}

.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}

/**** Isotope CSS3 transitions ****/

.isotope,
.isotope .isotope-item {
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -ms-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;
}

.isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    -ms-transition-property: height, width;
    -o-transition-property: height, width;
    transition-property: height, width;
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -ms-transition-property: -ms-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
    -webkit-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -ms-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
}

/* End: Recommended Isotope styles */

/* disable CSS transitions for containers with infinite scrolling*/

.isotope.infinite-scrolling {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

#testimonial {
    background-size: cover;
    padding: 0 0 80px 0;
}

#testimonial .container {
    top: 10%;
    position: relative;
}

#testimonial h4 {
    color: #fff;
    margin-bottom: 0;
}

#testimonial small {
    display: block;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.7);
}

#testimonial .btns {
    margin-top: 10px;
}

#testimonial .col-sm-6 {
    border-bottom: 1px solid #f4f4f4;
    border-right: 1px solid #f4f4f4;
    padding-bottom: 20px;
}

#testimonial .col-sm-6:nth-child(2n+0) {
    border-right: 0px solid #f4f4f4;
}

#testimonial .col-sm-6:nth-child(3),
#testimonial .col-sm-6:nth-child(4) {
    border-bottom: 0px solid #06F;
}

#testimonial .panel-one {
    padding-bottom: 20px;
    padding-top: 20px;
    float: left;
    height: 200px;
    overflow: hidden;
}

#testimonial .user-img {
    width: 25%;
    margin-right: 15px;
    float: left;
}

#testimonial .user-img img {
    border-radius: 50%;
    border: 4px solid #e5e5e5;
    width: 100%;
}

#testimonial .testi-info {
    width: 66%;
    float: left;
}

#testimonial .testi-info h4 {
    font-size: 18px;
    color: #454545;
    margin: 0px;
    padding-top: 15px;
}

#testimonial .testi-info h5 {
    font-size: 14px;
    color: #454545;
    margin: 0px;
    padding-bottom: 10px;
    font-weight: normal;
}

#testimonial .testi-info p {
    font-size: 15px;
    color: #454545;
    line-height: 20px;
}

/* .contact-container {
  content: "";
  opacity: 0.5; 
  background-size: cover;
  background-image: url('../images/bg2.jpg');
  width: 100%;
  height: 300px;
  display: block;
  position: absolute;
} */

#meet-team {
    padding: 87px 0 87px 0;
}

#meet-team .team-member {
    padding: 13px;
    background: #eeeeee;
    border: 2px solid #eeeeee;
    transition: border-color 400ms;
    -webkit-transition: border-color 400ms;
}

#meet-team .team-member .team-img {
    margin: -15px -15px 0 -15px;
}

#meet-team .team-member .team-info {
    /*  padding-bottom: 10px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 10px;*/
}

.team-info h3 {
    margin-bottom: 0px;
}

#meet-team .team-member:hover {
    border-color: #45aed6;
}

#meet-team .team-member:hover .social-icons>li>a {
    background: #45aed6;
}

#meet-team .social-icons {
    list-style: none;
    padding: 0;
    margin: 0;
}

#meet-team .social-icons>li {
    display: inline-block;
}

#meet-team .social-icons>li>a {
    display: block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    background: #222534;
    border-radius: 3px;
}

.divider {
    margin-top: 50px;
    margin-bottom: 50px;
    background-image: -moz-linear-gradient(180deg, #ffffff 0%, #e3e3e3 49%, #ffffff 100%);
    background-image: -webkit-linear-gradient(180deg, #ffffff 0%, #e3e3e3 49%, #ffffff 100%);
    background-image: -ms-linear-gradient(180deg, #ffffff 0%, #e3e3e3 49%, #ffffff 100%);
    width: 95%;
    height: 1px;
}

.progress {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #eeeeee;
}

.progress .progress-bar.progress-bar-primary {
    background-image: -moz-linear-gradient(4deg, #2caab3 0%, #2c8cb3 100%);
    background-image: -webkit-linear-gradient(4deg, #2caab3 0%, #2c8cb3 100%);
    background-image: -ms-linear-gradient(4deg, #2caab3 0%, #2c8cb3 100%);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.nav.main-tab {
    background: #eee;
    border-bottom: 3px solid #222534;
    border-radius: 3px 3px 0 0;
}

.nav.main-tab>li>a {
    color: #272727;
}

.nav.main-tab>li.active>a {
    background: #222534;
    color: #45aed6;
}

.nav.main-tab>li:first-child>a {
    border-radius: 3px 0 0 0;
}

.nav.main-tab>li:last-child>a {
    border-radius: 0 3px 0 0;
}

.tab-content {
    border: 1px solid #eee;
    border-top: 0;
    padding: 20px 20px 10px;
    border-radius: 0 0 3px 3px;
}

.panel-default {
    border-color: #eee;
}

.panel-default>.panel-heading {
    background-color: #fff;
    border-color: #eee;
}

.panel-default>.panel-heading .panel-title {
    font-size: 14px;
    font-weight: normal;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #eee;
}

#animated-number {
    padding: 87px 0 87px;
    /* background: #132125 url(../images/bg.jpg) no-repeat 0 0; */
    background-size: cover;
    color: #fff;
}

#animated-number h1,
#animated-number h2,
#animated-number h3,
#animated-number h4 {
    color: #fff;
}

#animated-number strong {
    display: block;
    margin-bottom: 30px;
    font-size: 22px;
    /* font-family: 'Roboto-light'; */
    text-transform: capitalize;
}

.animated-number {
    display: inline-block;
    /* font-family: 'Roboto-medium'; */
    width: 180px;
    height: 180px;
    font-size: 29px;
    line-height: 180px;
    border: 5px solid #fff;
    border-radius: 100px;
    margin-bottom: 20px;
}

#pricing {
    padding: 84px 0 70px 0px;
    background: #c4f0fb;
}

#pricing ul.pricing {
    list-style: none;
    padding: 0;
    margin: 10px 0 30px;
    border: 1px solid #eee;
    border-radius: 5px 5px 4px 4px;
    padding: 15px;
    text-align: center;
    background: #fff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

#pricing ul.pricing:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease 0s;
}

#pricing ul.pricing li {
    display: block;
    font-size: 18px;
    padding: 5px 10px;
    background: #fff;
}

#pricing ul.pricing li.plan-header {
    border-radius: 4px 4px 0 0;
    margin: -15px -15px 10px;
    padding: 15px 15px 30px;
    border: 0;
}

#pricing ul.pricing li.plan-header .price-duration {
    position: relative;
    margin-top: -63px;
    top: -10px;
    display: inline-block;
    width: 116px;
    height: 116px;
    background-image: -moz-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
    background-image: -webkit-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
    background-image: -ms-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
    color: #fff;
    border-radius: 100px;
    border: 5px solid #f6f6f6;
}

#pricing ul.pricing li.plan-header .price-duration>span {
    display: block;
    line-height: 1;
}

#pricing ul.pricing li.plan-header .price-duration>span.price {
    font-size: 24px;
    font-weight: 700;
    margin-top: 35px;
}

#pricing ul.pricing li.plan-header .price-duration>span.duration {
    margin-top: 5px;
}

#pricing ul.pricing li.plan-header .plan-name {
    margin-top: 10px;
    font-size: 20px;
    /* font-family: 'Roboto-medium'; */
    color: #272727;
    font-weight: normal;
    line-height: 2;
    text-transform: uppercase;
}

#pricing ul.pricing li.plan-header .plan-price {
    margin-top: 10px;
    font-size: 60px;
    color: #454545;
    font-weight: 600;
    line-height: 1;
    /* font-family: 'Roboto-regular'; */
    text-transform: uppercase;
}

#pricing ul.pricing li.plan-purchase .btn-primary {
    background: #00BFFF;
    border-radius: 30px;
    margin-top: 25px;
    padding: 15px 25px 10px;
    font-size: 18px;
    /* font-family: 'Roboto-medium'; */
}

#pricing ul.pricing li.plan-header .plan-price span {
    font-size: 18px;
    display: block;
    text-align: center;
    text-transform: lowercase;
    font-weight: normal;
}

#pricing ul.pricing.featured {
    background: #222534;
    color: #fff;
    border: 0;
}

#pricing ul.pricing.featured li.plan-header {
    background-image: -moz-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
    background-image: -webkit-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
    background-image: -ms-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
}

#pricing ul.pricing.featured li.plan-header .plan-name {
    color: #fff;
}

#get-in-touch {
    padding: 60px 0 50px;
    color: #454545;
    background: #f1f1f1;
}

#get-in-touch h1,
#get-in-touch h2 {
    color: #454545;
}

#get-in-touch .address {
    float: left;
    width: 100%;
    margin-bottom: 25px;
}

#get-in-touch .address h4 {
    font-size: 16px;
    color: #454545;
    margin: 0px;
    padding-bottom: 5px;
}

#get-in-touch .address p {
    font-size: 16px;
    color: #454545;
    margin: 0px;
    padding-bottom: 5px;
}

#get-in-touch .address a {
    color: #454545;
}

#get-in-touch .form-control {
    height: 40px;
    /* font-family: 'Roboto-regular'; */
    color: #323232;
}

#get-in-touch textarea.form-control {
    height: 135px;
    color: #323232;
}

#get-in-touch .btn.btn-primary {
    /* font-family: 'Roboto-medium'; */
    font-size: 22px;
    padding: 8px 40px;
}

#blog {
    padding: 87px 0 87px 0;
    background: #f6f6f6;
    overflow: hidden;
}

#blog .blog-post {
    padding: 15px;
    background: #fff;
}

#blog .blog-post .item {
    height: 200px;
}

#owl-example .item {
    margin: 15px;
}

#blog .blog-post .post-format {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 34px;
    text-align: center;
    color: #fff;
    background: #222534;
    border: 3px solid #fff;
    border-radius: 100px;
}

#blog .blog-post.blog-large .entry-thumbnail {
    margin: -15px -15px 15px;
    position: relative;
}

#blog .blog-post.blog-large .entry-thumbnail img {
    max-width: 100%;
}

#blog .blog-post.blog-large .post-format {
    width: 66px;
    height: 66px;
    line-height: 60px;
    position: absolute;
    right: 20px;
    bottom: -33px;
}

#blog .blog-post.blog-large .post-format>i {
    font-size: 20px;
}

#blog .blog-post.blog-large .entry-date {
    font-size: 12px;
    /* font-family: 'Roboto-regular'; */
    display: block;
    margin-bottom: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #45aed6;
}

#blog .blog-post.blog-large .entry-title {
    margin-top: 0;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 15px;
}

#blog .blog-post.blog-large .entry-title a {
    color: #64686d;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    /* font-family: 'Roboto-medium'; */
}

#blog .blog-post.blog-large .entry-title a:hover {
    color: #45aed6;
}

#blog .blog-post.blog-media {
    margin-bottom: 30px;
}

#blog .blog-post.blog-media .entry-thumbnail {
    margin: -15px 15px -15px -15px;
    position: relative;
}

#blog .blog-post.blog-media .post-format {
    position: absolute;
    top: 20px;
    right: -10px;
}

#blog .blog-post.blog-media .entry-date {
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: #45aed6;
}

#blog .blog-post.blog-media .entry-title {
    margin-top: 0;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 15px;
}

#blog .blog-post.blog-media .entry-title a {
    color: #64686d;
}

#blog .blog-post.blog-media .entry-title a:hover {
    color: #45aed6;
}

#blog .blog-post .entry-meta {
    margin-top: 20px;
    border-top: 1px solid #eee;
    padding-top: 15px;
}

#blog .blog-post .entry-meta>span {
    display: inline-block;
    margin-right: 10px;
    color: #999;
}

#blog .blog-post .entry-meta>span>a {
    color: #999;
}

#blog .blog-post .entry-meta>span>a:hover {
    color: #45aed6;
}

#blog .btn.btn-primary {
    /* font-family: 'Roboto-regular'; */
    font-size: 15px;
    margin-top: 20px;
    padding: 10px 30px;
}

#contact {
    position: relative;
    overflow: hidden;
}

#contact .container-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(69, 174, 214, 0.3);
    z-index: 1;
}

#contact .contact-form {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    margin-top: 50px;
}

#contact .contact-form h3 {
    margin-top: 0;
}

#contactus img {
    width: 50%;
    height: 50%;
}

/***********************
********* Footer ******
************************/

#footer {
    /* padding-top: 10px;
  padding-bottom: 10px; */
    color: #fff;
    background: rgb(68, 68, 68);
}

#footer a {
    color: #fff;
}

#footer a:hover {
    color: #45aed6;
}

#footer ul {
    list-style: none;
    padding: 0;
    margin: 0 -7.5px;
}

#footer ul>li {
    display: inline-block;
    margin: 0 7.5px;
}

@media only screen and (min-width: 768px) {
    #footer .social-icons {
        float: right;
    }
}

.form-control {
    box-shadow: none;
    -webkit-box-shadow: none;
}

@media (min-width: 768px) and (max-width:999px) {
    #main-menu.navbar-default .navbar-nav>li>a {
        padding-left: 8px;
        padding-right: 8px;
        font-size: 15px;
    }
    #meet-team .team-member {
        margin-bottom: 25px;
    }
    #meet-team .team-member .team-img,
    #meet-team .team-member .team-info h3 {
        text-align: center;
    }
    #meet-team .team-member .team-info span {
        text-align: center;
        display: block;
    }
}

@media (max-width:767px) {
    #meet-team .team-member {
        margin-bottom: 25px;
    }
    #meet-team .team-member .team-img,
    #meet-team .team-member .team-info h3 {
        text-align: center;
    }
    #meet-team .team-member .team-info span {
        text-align: center;
        display: block;
    }
    #blog .blog-post {
        margin-bottom: 20px;
    }
}

@media (max-width:500px) {
    #blog .blog-post.blog-large .entry-thumbnail img,
    .team-img img {
        width: 100%;
    }
    .animated-number {
        height: 130px;
        width: 130px;
        line-height: 130px;
        font-size: 22px;
    }
    #animated-number strong {
        font-size: 17px;
    }
    #blog .blog-post.blog-large .entry-thumbnail {
        padding-top: 0px;
    }
}

.ourclient-bg {
    background-color: rgb(255, 255, 255);
}

.quality-bg {
    background-color: rgb(243, 243, 243);
}

.ourclient {
    margin: 0px 0px 0px 20px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ourclient img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
}

.chooseus img {
    max-width: 50%;
    max-height: 50%;
    display: block;
    margin: 0 auto;
}

.chooseus-detail {
    position: relative;
    display: flex;
    flex-direction: row;
}

@media (max-width:500px) {
    .chooseus-detail {
        position: relative;
        display: flex;
        flex-direction: column;
    }
}

#chooseus .section-header h2,
h3 {
    color: #000000;
}

#head-text h1 {
    text-align: center;
    /* margin: 200px auto; */
    /* font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif; */
    text-transform: uppercase;
    color: #00BFFF;
    /* text-shadow: 0px 0px 0px #eee, 0px 2px 3px #707070; */
}

.text-white {
    color: white;
}

.text-on-image p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#main .text-block p {
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    line-height: 2;
}

#main .text-block .btn {
    margin-top: 20px;
    background: transparent;
    border: 3px solid #00BFFF;
    border-radius: 30px;
}

#main .text-block .btn.btn-primary {
    padding: 10px 46px;
    font-size: 22px;
}

#main .text-block .btn.btn-primary:hover {
    background: #00BFFF;
}

.head-info {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 300px;
    /* opacity: 0.8; */
    z-index: -1;
    /* margin-top: 100px; */
}

@media (max-width:500px) {
    .head-info {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 300px;
        /* opacity: 0.8; */
        z-index: -1;
        margin-top: 100px;
    }
}

#service .head-info {
    /* margin-top: 150px; */
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
}

.text-block {
    padding-top: 50px;
}

.text-block h1 {
    color: #fff;
    overflow: auto;
}

.detail-info {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.detail-info img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    /* margin: 0 auto; */
}

/* .detail-info {
  background-position: center center no-repeat;
  background-size: cover;
  width: auto;
  height: 400px;
  max-height: 400px;
} */

.quality-info {
    background-position: center no-repeat;
    background-size: cover;
    width: 100%;
    height: 500px;
}

.media-body h4,
h5,
h6 {
    /* font-family: "Roboto-medium"; */
}

.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.col-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.name img {
    width: 50%;
    height: 50%;
}

.main-menu-bg {
    background-color: rgb(255, 255, 255) !important;
}

#qualityproduct p {
    padding-left: 30px;
    padding-right: 30px;
}

#detailinfo p {
    padding-left: 10px;
    padding-right: 10px;
}

.about-bg {
    background-position: center center no-repeat;
    height: 500px;
    background-size: 100% 100%;
    width: auto !important;
}

/* @media (min-width: 992px){
    .navbar .navbar-default .container {
        width: 300px !important;
    }
}

@media (min-width: 768px){
    navbar .navbar-default .container {
            width: 300px !important;
    }
} */

@media (min-width: 940px) {
    #main .text-block p {
        font-size: 40px;
        color: #ffffff;
        font-weight: 600;
        text-align: center;
        line-height: 2;
    }
    .text-block {
        width: 100%;
        position: absolute;
        top: 20px;
        margin-top: 0;
        margin-right: auto;
        margin-left: auto;
        padding-top: 150px;
    }
    .head-info {
        background-repeat: no-repeat;
        background-size: cover !important;
        height: 100vh;
        /* margin-top: 5px; */
    }
    .section-height {
        height: 500px;
    }
    .ourclient-height {
        height: 400px;
    }
    .name img {
        width: 70%;
        height: 70%;
    }
    .about-bg {
        background-position: center center no-repeat;
        height: 650px;
        background-size: 100% 100%;
        width: auto !important;
    }
    .chooseus img {
        max-width: 100%;
        max-height: 150px;
        display: block;
        margin: 0 auto;
    }
    .ourclient {
        margin: 20px !important;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #contactus img {
        width: 100%;
        height: 100%;
    }
    #product {
        padding-top: 100px;
        /* height: 600px; */
    }
    #subCategory {
        padding-right: 30px;
        padding-bottom: 30px;
    }
    #aboutus .steps-section {
        padding-top: 30px !important;
    }
    #aboutus {
        padding-top: 80px !important;
        background-attachment: fixed !important;
        z-index: 1 !important;
        background-color: rgba(0, 0, 0, 0.59) !important;
        background-blend-mode: overlay !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        height: 100vh !important;
    }
    #product .product-name p {
        font-size: 30px !important;
        font-weight: 600;
        padding-top: 50px !important;
        padding-bottom: 20px;
        text-align: center;
        color: #ffffff;
    }
    #service .text-block p {
        color: #0f467a;
        font-size: 7rem;
        font-weight: 600;
        padding-top: 200px;
    }
    #service .head-info {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        /* opacity: 0.8; */
        z-index: -1;
        margin-top: 100px;
        height: 90vh !important;
    }
    #service-used p {
        padding-top: 20px;
        color: #ffffff;
        font-size: 26px;
        /* font-family: 'Roboto-regular', sans-serif */
    }
    #service-workstep .card {
        font-size: 1em;
        overflow: hidden;
        padding: 0;
        border: none;
        border-radius: .28571429rem;
        box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
        width: 100%;
        display: inline-block;
    }
    #service-compare .right-panel {
        border-left: solid #ffffff ! important;
    }
    #service-workstep .step-circle img {
        width: 50% !important;
    }
    #color .card-title {
        padding: 10px;
        font-weight: 400;
        font-size: 14px !important;
        max-height: 50px;
    }
    #productList .table {
        background-color: #ffffff;
        width: 100% !important;
        display: inline-table !important;
    }
    #subCategory .btn.btn-primary {
        padding: 10px 46px;
        font-size: 16px !important;
    }
    #color .card-item {
        width: auto;
        height: 200px !important;
    }
    #color {
        margin-top: 50px !important;
    }
    #warehouse-gallery {
        width: 100%;
        height: 100% !important;
    }
}

#warehouse-gallery {
    width: 100%;
    height: 100vh;
}

#warehouse-gallery .row-gallery {
    display: table;
    width: 100%;
    margin-bottom: 50px;
}

#warehouse-gallery .row-gallery .content-gallery {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    float: none;
}

#aboutus {
    padding-top: 0px;
    background-attachment: fixed;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.2);
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
    height: unset;
}

#aboutus .section-header h2,
h3 {
    color: #000000;
}

#aboutus .steps-description {
    color: #000000;
}

#aboutus .steps-section {
    padding-top: 0px;
    padding-bottom: 60px !important;
}

.carousel {
    width: auto;
    height: 200 !important;
}

.carousel .item img {
    display: inline;
}

.carousel-control.left {
    background-image: -webkit-linear-gradient(left, rgba(243, 243, 243, 0.5) 0, rgba(243, 243, 243, 0.5) 100%);
}

.carousel-control.right {
    background-image: -webkit-linear-gradient(left, rgba(243, 243, 243, 0.5) 0, rgba(243, 243, 243, 0.5) 100%);
}

.carousel-control {
    color: black;
}

.headerProduct {
    background-color: rgb(16, 69, 120);
    color: "#fff";
    text-align: "center";
}

.btn {
    cursor: pointer !important;
}

#subCategory .btn {
    margin-top: 20px;
    background: '#fffff';
    border: 3px solid #00BFFF;
    border-radius: 30px;
    background: #ffffff;
    color: #00BFFF;
}

#subCategory .btn.btn-primary {
    padding: 10px 46px;
    font-size: 12px;
}

#subCategory .btn.btn-primary:hover {
    background: #00BFFF;
    color: rgb(255, 255, 255);
}

#productList {
    padding-top: 30px;
    padding-bottom: 30px;
}

#productList .table {
    background-color: #ffffff;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 0;
    table-layout: fixed;
}

#productList .table td {
    word-wrap: break-word;
    white-space: normal;
}

#productList .table-striped>tbody>tr:nth-child(2n+1)>td,
.table-striped>tbody>tr:nth-child(2n+1)>th {
    background-color: #00bfff3d;
}

#product .product-name p {
    font-size: 20px;
    font-weight: 600;
    padding-top: 150px;
    text-align: center;
    color: #ffffff;
}

#product {
    background-attachment: fixed;
    z-index: 1;
    background-color: rgba(65, 65, 65, 0.82);
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
}

.loader:empty {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid #000000;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#warehouse-gallery {
    padding-top: 100px;
    padding-bottom: 80px;
    background-color: lightgray;
}

#service-detail {
    /* padding-top: 50px; */
    /* padding-bottom: 50px; */
    background-color: #87cefa6e;
}

#service-used,
#service-compare {
    background-color: #077093;
}

#service-used p {
    padding-top: 20px;
    color: #ffffff;
    font-size: 20px;
    /* font-family: 'Roboto-regular', sans-serif */
}

#service-used .panel-title {
    font-size: 2em;
    font-weight: 600;
    text-align: left;
}

#service-used .right-panel {
    height: 500px;
    background-color: #ffffff3d;
}

/* #service-used .right-panel img {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
} */

#service-used .right-panel .item {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

#service-used .right-panel .card {
    font-size: 1em;
    overflow: hidden;
    padding: 0;
    border: 3px solid #ffffff;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    width: 100%;
    display: inline-block;
}

#service-used .right-panel .card-img-top {
    display: block;
    width: 100%;
    height: auto;
}

#service-workstep,
#service-table {
    background-color: #ffffff;
}

#service-workstep .panel-title {
    font-size: 2em;
    font-weight: 600;
    text-align: right;
}

#service-workstep img {
    width: 100%;
}

#service-compare {
    padding-bottom: 50px;
}

#service-compare .heading p {
    color: #ff8f00;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
}

#service-compare .heading {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 30px;
    color: #ffffff;
}

#service-compare .right-panel {
    border-left: unset;
}

#service-compare .panel-title {
    font-size: 2em;
    font-weight: 600;
    text-align: center;
}

#service-compare .panel-body p {
    text-align: justify;
    text-justify: inter-word;
}

#service-compare p {
    padding-top: 16px;
    color: #ffffff;
    font-size: 20px;
    /* font-family: 'Roboto-regular', sans-serif */
}

#service-table .panel-title {
    font-size: 2em;
    font-weight: 600;
    text-align: center;
}

#service-table .heading {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 30px;
    color: #000000;
}

#service-used .heading p {
    color: #ff8f00;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
}

#service-used .left-bg {
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

#service-used img {
    max-height: 300px;
    width: auto;
}

.service-step {
    height: 500px;
    margin-bottom: 30px;
}

.service-step img {
    max-height: 500px;
    width: auto;
}

.service-step-item {
    height: 200px;
}

#service-workstep {
    padding-top: 30px;
    padding-bottom: 30px;
}

#service-workstep .heading p {
    color: #000000;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
}

#service-workstep .card {
    font-size: 1em;
    overflow: hidden;
    padding: 0;
    border: none;
    border-radius: .28571429rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    width: 80%;
    display: inline-block;
}

#service-workstep .card-block {
    font-size: 20px;
    position: relative;
    margin: 0;
    padding: 5px;
    border: none;
    border-top: 1px solid rgba(34, 36, 38, .1);
    box-shadow: none;
}

#service-workstep .card-img-top {
    display: block;
    width: 100%;
    height: auto;
}

#service-table table {
    color: black;
    margin-bottom: 50px;
}

#service-table .heading p {
    color: #000000;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
}

#service-table th {
    text-align: center;
    width: 25%;
    height: 100px;
    border: none;
    vertical-align: middle;
    background-color: #d3d3d333;
    border: solid white;
    font-size: 22px;
}

#service-table th:nth-child(1) {
    background-color: unset;
}

#service-table td {
    text-align: center;
    width: 25%;
    border-top: none;
    border-left: solid white;
    border-right: solid white;
    border-bottom: none;
    font-size: 16px;
}

/* #service-table .table-striped tbody tr:nth-child(odd) td, #service-table .table-striped tbody tr:nth-child(odd) th {
  background-color: #ffffff;
} */

/* #service-table .table-striped tbody tr:nth-child(even) td, #service-table .table-striped tbody tr:nth-child(even) th {
  background-color: #8D3841;
  color: #fff;
} */

#color {
    margin-top: 10px;
    padding-bottom: 50px;
}

#color .card-title {
    padding: 10px;
    font-weight: 400;
    font-size: 10px;
    max-height: 30px;
}

#color .card-item {
    width: auto;
    height: 100px;
}

#color .card-empty {
    position: relative;
    top: 50%;
}

.td-green {
    background-color: #25b29d;
}

.td-green-light {
    background-color: #25b29da8;
}

.td-yellow {
    background-color: #ffd100;
}

.td-yellow-light {
    background-color: #ffd100a8;
}

.td-blue {
    background-color: #00a4ff;
}

.td-blue-light {
    background-color: #00a4ffa8;
}

.td-red {
    background-color: #ce1c0c;
}

.td-red-light {
    background-color: #ce1c0ca8;
}

#wood,
#coal {
    padding-top: 120px;
    min-height: 100vh;
}

#wood,
#coal {
    background-attachment: fixed;
    z-index: 1;
    background-color: rgba(65, 65, 65, 0.82);
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
}

#wood h1,
#coal h1 {
    color: white;
}

#wood .card {
    display: block;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    transition: box-shadow .25s;
}

#wood .card:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (max-width: 768px) {
    #wood .img-card,
    #coal .img-card {
        /* width: 100%; */
        min-height: unset !important;
    }

    #wood .card-content {
        padding: 15px;
        text-align: left;
        height: unset !important;
    }
    
}

#wood .img-card,
#coal .img-card {
    /* width: 100%; */
    min-height: 300px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    overflow: hidden;
}

#wood .img-card img {
    /* width: 100%; */
    /* max-height: 400px; */
    height: 100%;
    object-fit: contain;
    transition: all .25s ease;
}

#coal .img-card img {
    /* width: 100%; */
    /* max-height: 400px; */
    height: 100%;
    object-fit: cover;
    transition: all .25s ease;
}

#wood .card-content {
    padding: 15px;
    text-align: left;
    min-height: 300px;
}

#wood .card-title {
    margin-top: 0px;
    font-weight: 700;
    font-size: 1.65em;
}

#wood .card-title span {
    color: #000;
    text-decoration: none !important;
}

#wood .card-read-more {
    border-top: 1px solid #D4D4D4;
}

#wood .card-read-more span {
    text-decoration: none !important;
    padding: 10px;
    font-weight: 600;
    text-transform: uppercase
}