@import url(https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic);
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

body {
  font-family: lato;
}

.section-header, .steps-header, .steps-name {
  color: #00BFFF;
  font-weight: 400;
  font-size: 1.4em;
}

/* .steps-title {
  color: rgb(0, 0, 0);
  font-weight: 400;
  font-size: 1.4em;
  position: absolute;
    top: 50%;
    left: 50%;
} */

.steps-header {
  margin-bottom: 20px;
  text-align: center;
}

.steps-timeline {
  outline: 1px dashed rgba(255, 0, 0, 0);
}
@media screen and (max-width: 500px) {
  .steps-timeline {
    /* border-left: 2px solid #00BFFF; */
    margin-left: 25px;
  }
}
@media screen and (min-width: 500px) {
  .steps-timeline {
    border-top: 3px solid #00BFFF;
    /* padding-top: 20px; */
    margin-top: 40px;
    margin-left: 16.65%;
    margin-right: 16.65%;
  }
}
.steps-timeline:after {
  content: "";
  display: table;
  clear: both;
}

.steps-one,
.steps-two,
.steps-three,
.steps-four {
  outline: 1px dashed rgba(0, 128, 0, 0);
}
@media screen and (max-width: 500px) {
  .steps-one,
  .steps-two,
  .steps-three {
    margin-left: -25px;
  }

  .steps-four {
    margin: 40px 25px 0px 0px;
  }
}
@media screen and (min-width: 500px) {
  .steps-one,
  .steps-two,
  .steps-three,
  .steps-four {
    float: left;
    width: 20%;
    margin-top: -50px;
  }
}

@media screen and (max-width: 500px) {
  .steps-one,
  .steps-two {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 500px) {
  .steps-one {
    margin-left: -14.65%;
    margin-right: 16.65%;
  }
}

@media screen and (min-width: 500px) {
  .steps-three {
    margin-left: 16.65%;
    margin-right: -16.65%;
  }

  .steps-four {
    margin-left: 32.65%;
    margin-right: -16.65%;
  }
}

.steps-img {
  background-color: #00BFFF;
  display: block;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
@media screen and (max-width: 500px) {
  .steps-img {
    float: left;
    margin-right: 20px;
  }
}

.steps-name,
.steps-description {
  margin: -30px;
}

@media (min-width: 940px)  {
  .steps-name {
    text-align: center;
  }

  .steps-section {
    padding-top: 30px;
    padding-bottom: 50px;
  }

  .steps-description {
    margin-top: 20px;
    overflow: hidden;
    text-align: center;
    font-size: 20px;
    padding-bottom: 40px;
  }
}

.steps-description {
  margin-top: 20px;
  overflow: hidden;
  text-align: inherit;
  font-size: 20px;
  padding-bottom: 40px;
}
.shape {
  line-height:60px;
  margin:auto;
  position:relative;
  background-color: #00BFFF;
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  font-size: x-large;
  padding-top: 15px;
  color: #ffffff;
  /* border: 3px rgb(16,69,120) solid; */
}

.steps-section {
  padding-left: 30px;
  padding-right: 30px;
}