.navbar {
    padding: 0;
}

.head-contact {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: rgb(16, 69, 120);
}

.head-contact label {
    font-weight: 300;
    color: rgb(255, 255, 255);
}

.active-link a {
    background: transparent;
    color: #00BFFF !important;
}

.active-link .navbar-default .navbar-nav li a {
    background: transparent;
    color: #00BFFF !important;
    font-size: 14px;
}

.navbar-default .navbar-nav li a {
    font-size: 14px;
}

.navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
    background-color: #ffffff;
}

.navbar.navbar-default .navbar-toggle {
    margin-top: 10px;
}

.navbar.navbar-default {
    padding-top: 5px;
}

#menu .show-grid {
    display: table;
    width: 100%;
}

#menu .show-grid .name {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    float: none;
}

#menu .show-grid .page-menu {
    width: 100%;
    float: right;
}

#menu span {
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
}
#menu .name-blue {
    color: #00BFFF;
}

#menu .name-hard-blue {
    color: #0f467a;
}

@media (min-width: 940px) {
    .head-contact {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: rgb(16, 69, 120);
        margin-right: 40px;
    }
    .navbar.navbar-default {
        padding-top: 5px;
        padding-bottom: 5px;
        border: 0;
        border-radius: 0;
        background: #fff;
    }
    .navbar.navbar-default .navbar-toggle {
        margin-top: 32px;
    }
    .head-contact label {
        font-weight: 300;
        color: #ffffff;
    }
    .active-link .navbar-default .navbar-nav li a {
        background: transparent;
        color: #00BFFF !important;
        font-size: 18px;
    }

    .navbar-default .navbar-nav li a {
        font-size: 20px;
    }

    #menu span {
        font-size: 40px;
    }
}