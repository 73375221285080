 
@media (min-width: 1200px){
    .section-warehouse-height{
      /* height: 600px; */
      display: none;
    }
}

  .warehouse-bg {
    /* background-color: rgb(243, 243, 243); */
    background-color: #fff;
  }
  
/* .warehouse-bg {
    background-position: center no-repeat;
    background-size: cover;
    width: 100%;
    height: 400px;
  } */

.card {
    display: block;
      margin-bottom: 20px;
      line-height: 1.42857143;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
      transition: box-shadow .25s;
  }
  .card:hover {
    box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  }
  .img-card {
    width: 100%;
    height:200px;
    border-top-left-radius:2px;
    border-top-right-radius:2px;
    display:block;
      overflow: hidden;
  }
  .img-card img{
    width: 100%;
    height: 200px;
    object-fit:cover;
    transition: all .25s ease;
  }
  .card-content {
    padding:15px;
    text-align:left;
  }
  .card-title {
    margin-top:0px;
    font-weight: 700;
    font-size: 1.65em;
  }
  .card-title a {
    color: #000;
    text-decoration: none !important;
  }
  .card-read-more {
    border-top: 1px solid #D4D4D4;
  }
  .card-read-more a {
    text-decoration: none !important;
    padding:10px;
    font-weight:600;
    text-transform: uppercase
  }

  .warehouse-item {
    background-position: center no-repeat;
    background-size: cover;
    width: 100%;
    height: 350px;
    display: block;
    border-radius: 2px;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
      transition: box-shadow .25s;
      overflow: hidden;
  }

  .warehouse-item .slider-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
  }

  .warehouse-item .slider-inner .btn {
    position: absolute;
    margin-right: 50px;
    margin-left: 50px;
    margin-bottom: 20px !important; 
    bottom: 0;
    left: 0;
    right: 0;
    background:transparent; 
    border:2px solid rgb(255, 255, 255); 
    border-radius:0px;
    
  }

  .warehouse-item .slider-inner .btn.btn-primary{ padding:10px 46px; font-size:18px;}
  .warehouse-item .slider-inner .btn.btn-primary:hover{ background:#00BFFF;}

  .warehouse-button .btn{
    margin-top: 20px;
    margin-bottom: 20px;
    background:transparent; 
    border:2px solid rgb(255, 255, 255); 
    border-radius:0px;
    color: #00BFFF;
  }
  .warehouse-button .btn.btn-primary{
    padding:10px 46px; 
    font-size:18px;
  }

  .warehouse-button .btn.btn-primary:hover{
    background:#00BFFF;
    color: #fff;
  }